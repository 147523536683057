import PropTypes from "prop-types";
import React, { useState } from "react";
import { Group, Text, rem, Button, TextInput, Container } from "@mantine/core";
import { IconUpload, IconFile, IconX, IconXboxX } from "@tabler/icons-react";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";

import {
  foundCollectionFetch,
  handleDropProcess,
} from "../utils/collectionFound/general";

const VerifyCollection = ({
  props,
  onShowCollectionFound,
  onContentCollectionFound,
  onFileName,
  onErrorMessage,
}) => {
  const [files, setFiles] = useState([]);

  const [newData, setNewData] = useState("");
  const [loading, setLoading] = useState(false);

  const handleDrop = async (droppedFiles) => {
    await handleDropProcess(
      droppedFiles,
      setFiles,
      form,
      setNewData,
      onErrorMessage,
    );
  };

  // Previews (only one file)
  const preview = files.length > 0 ? files[0].name : null;

  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      new_csv: "",
    },
  });

  //Submit form
  const handleFormSubmit = async (values) => {
    try {
      setLoading(true);

      const csrfToken = document.querySelector(
        "[name=csrfmiddlewaretoken]",
      ).value;

      try {
        const result = await foundCollectionFetch(values.new_csv, csrfToken);

        if (result.success) {
          onFileName(preview);
          setFiles([]);
          setNewData("");
          form.reset();
          onShowCollectionFound(true);
          onContentCollectionFound(result.data);
        } else {
          console.error(result.error);
          notifications.show({
            title: "Error",
            message: result.error,
            color: "red",
          });
          onErrorMessage(result.error);
        }
      } catch (error) {
        console.error("Error:", error);
        notifications.show({
          title: "Error",
          message: "Error uploading CSV",
          color: "red",
        });
      } finally {
        setLoading(false);
      }
    } catch (error) {
      console.error("Submission failed:", error);
    }
  };

  // Remove file
  const handleClickRemoveFile = () => {
    form.reset();
    setFiles([]);
    setNewData("");
  };
  return (
    <div id="verify-method-csv__screen" className="rb-method-csv__screen">
      <div className="rb-method">
        <div className="hash-screen-container csv-screen-container rb-container">
          <Dropzone
            styles={{
              root: { paddingTop: "1.5625em" },
            }}
            onDrop={handleDrop}
            onReject={(files) => console.log("rejected files", files)}
            accept={[MIME_TYPES.csv, MIME_TYPES.zip]}
            {...props}
          >
            <Group
              styles={{
                root: { display: "block" },
              }}
              justify="center"
              gap="xs"
              mih={50}
              style={{ pointerEvents: "none" }}
            >
              <Dropzone.Accept>
                <IconUpload
                  style={{
                    width: rem(52),
                    height: rem(52),
                    color: "var(--mantine-color-blue-6)",
                  }}
                  stroke={1.5}
                />
              </Dropzone.Accept>
              <Dropzone.Reject>
                <IconX
                  style={{
                    width: rem(52),
                    height: rem(52),
                    color: "var(--mantine-color-red-6)",
                  }}
                  stroke={1.5}
                />
              </Dropzone.Reject>
              <Dropzone.Idle>
                <IconFile
                  style={{
                    width: rem(52),
                    height: rem(52),
                    color: "var(--mantine-color-dimmed)",
                  }}
                  stroke={1.5}
                />
              </Dropzone.Idle>

              <div>
                <Text
                  size="xl"
                  inline
                  styles={{
                    root: { paddingTop: "0.625em" },
                  }}
                >
                  <a href="#">Load your CSV or ZIP file</a>
                </Text>
                <Text
                  size="sm"
                  c="dimmed"
                  inline
                  mt={7}
                  styles={{
                    root: { paddingTop: "0.625em" },
                  }}
                >
                  You can only load an archive with the{" "}
                  <a
                    href="https://docs.vbase.com/getting-started/web-tools/how-to-use-vbase-verify"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => e.stopPropagation()}
                    style={{ pointerEvents: "auto" }}
                  >
                    {" "}
                    appropriate structure
                  </a>
                  .
                </Text>
              </div>
            </Group>
          </Dropzone>
          {preview && (
            <div>
              File Loaded: <b>{preview}</b>
              <IconXboxX
                onClick={() => handleClickRemoveFile()}
                style={{
                  width: rem(18),
                  height: rem(18),
                  color: "var(--customColors-primary)",
                  marginLeft: "0.3em",
                  cursor: "pointer",
                }}
                stroke={1.5}
              />
            </div>
          )}
          {newData && (
            <Container
              size="26rem"
              classNames={{
                root: "rb-text-left w-100",
              }}
            >
              <form onSubmit={form.onSubmit(handleFormSubmit)}>
                <TextInput
                  pb="md"
                  type="hidden"
                  key={form.key("new_csv")}
                  {...form.getInputProps("new_csv")}
                />

                <Button
                  type="submit"
                  loading={loading}
                  styles={(theme) => ({
                    root: {
                      display: "block",
                      maxWidth: "none",
                      marginLeft: "auto",
                      marginRight: "auto",
                      backgroundColor: theme.customBackgrounds.blackPrimary,
                    },
                  })}
                >
                  Validate
                </Button>
              </form>
            </Container>
          )}
        </div>
      </div>
    </div>
  );
};

VerifyCollection.propTypes = {
  onShowCollectionFound: PropTypes.func.isRequired,
  onFileName: PropTypes.func.isRequired,
  onErrorMessage: PropTypes.func.isRequired,
  props: PropTypes.object,
  onContentCollectionFound: PropTypes.func,
};
export default VerifyCollection;
