import PropTypes from "prop-types";
import React from "react";
import { useMantineTheme } from "@mantine/core";
import { Title, Text, Container, Image } from "@mantine/core";

const CollectionNotFound = ({ staticBasePath, errorMessage }) => {
  // Mantine theme instance to apply custom styles and theming throughout the component
  const theme = useMantineTheme();

  return (
    <>
      <div className="rb-screen">
        <div className="rb-header-wrap">
          <Container size="sm" pt="lg">
            {errorMessage && (
              <>
                <Image
                  src={`${staticBasePath}public/images/svg/not_validate.svg`}
                  alt="Archive Cannot be Validated"
                  w={64}
                  className="rb-result-img align-center"
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
                <Title order={1}>Collection Not Found</Title>
                <Text
                  size="lg"
                  styles={() => ({
                    root: {
                      color: theme.customColors.red,
                    },
                  })}
                >
                  {errorMessage}
                </Text>
              </>
            )}
          </Container>
        </div>
      </div>
    </>
  );
};

CollectionNotFound.propTypes = {
  staticBasePath: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
};
export default CollectionNotFound;
