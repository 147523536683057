import React from "react";
import moment from "moment";
import { Flex, Image } from "@mantine/core";
import { formatHashCollectionUserFound } from "../formatCollectionUserFound";
import LogoBlockchainSvg from "../../svg/LogoBlockchainSvg";
import LogoVBSvg from "../../svg/LogoVBSvg";
import DownloadPDFUserFound from "../../components/DownloadPDFUserFound";
import {
  calculateTimeDifference,
  parseTimeDiff,
  parseDate,
  calculateTimeDifferenceInSeconds,
  formatTimeDifferenceFromSeconds,
} from "./resultScreen";

// Function for creating data
export const useTableData = (
  contentCollectionFound,
  handleClickToCopy,
  staticBasePath,
) => {
  if (!Array.isArray(contentCollectionFound?.matched_records)) {
    return [];
  }

  const newMatchedRecords = contentCollectionFound.matched_records.map(
    (item) => ({
      objectCid: (
        <Flex align="center" gap={8}>
          {formatHashCollectionUserFound(item.objectCid, 9, 6)}
          <Image
            w={15}
            src={`${staticBasePath}public/images/svg/vb-icon-copy-2.svg`}
            onClick={() => handleClickToCopy(item.objectCid)}
            style={{ cursor: "pointer" }}
            alt="Copy icon"
          />
        </Flex>
      ),
      timestamp: moment(item.timestamp)
        .utcOffset(0)
        .format("MMMM D, YYYY | hh:mm:ss A"),
      csv_timestamp: moment(item.csv_timestamp)
        .utcOffset(0)
        .format("MMMM D, YYYY | hh:mm:ss A"),
      time_stamp_diff: calculateTimeDifference(
        item.timestamp,
        item.csv_timestamp,
      ),
      blockchain_receipts: (
        <Flex align="center" justify="center" gap={10}>
          {item?.objectCid && (
            <a
              title="vBase Stamp Verification"
              href={`/verify/?cid=${item?.objectCid}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LogoVBSvg />
            </a>
          )}
          {item?.transactionHash && (
            <a
              title="Public Blockchain Record"
              href={`${item.blockExplorerUrl}${item?.transactionHash}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LogoBlockchainSvg />
            </a>
          )}
          <DownloadPDFUserFound
            user_id={item.user_id}
            user={item.user}
            collection_name={
              contentCollectionFound.collection_info.collection_name
            }
            collection_hash={
              contentCollectionFound.collection_info.collection_id
            }
            objectCid={item.objectCid}
            blockExplorerUrl={item.blockExplorerUrl}
            transactionHash={item.transactionHash}
            chainId={item.chainId}
            blockchainName={item.blockchainName}
            formattedDateTime={moment(item.timestamp)
              .utcOffset(0)
              .format("MMMM D, YYYY | hh:mm:ss A")}
          />
        </Flex>
      ),
    }),
  );

  return newMatchedRecords;
};

export const maxTimeStampDiff = (contentCollectionFound) => {
  if (!Array.isArray(contentCollectionFound?.matched_records)) {
    return 0;
  }

  const newMatchedRecords = contentCollectionFound.matched_records.map(
    (item) => ({
      time_stamp_diff_seconds: calculateTimeDifferenceInSeconds(
        item.timestamp,
        item.csv_timestamp,
      ),
    }),
  );

  const maxTimeStampDiffSeconds = newMatchedRecords.reduce(
    (max, record) => Math.max(max, record.time_stamp_diff_seconds),
    0,
  );
  return formatTimeDifferenceFromSeconds(maxTimeStampDiffSeconds);
};

// Function for creating columns
export const useTableColumns = () => [
  {
    accessorKey: "objectCid",
    header: "Content ID",
    enableSorting: true,
    size: 120,
    sortingFn: (rowA, rowB) => {
      const cidA = rowA.original.objectCid.props?.children?.[0] || "";
      const cidB = rowB.original.objectCid.props?.children?.[0] || "";
      if (cidA < cidB) return -1;
      if (cidA > cidB) return 1;
      return 0;
    },
  },
  {
    accessorKey: "timestamp",
    header: "Blockchain Timestamp",
    sortingFn: (rowA, rowB) => {
      const timestampA = parseDate(rowA.original.timestamp);
      const timestampB = parseDate(rowB.original.timestamp);
      return timestampA - timestampB;
    },
  },
  {
    accessorKey: "csv_timestamp",
    header: "Archive Timestamp",
    sortingFn: (rowA, rowB) => {
      const timestampA = parseDate(rowA.original.csv_timestamp);
      const timestampB = parseDate(rowB.original.csv_timestamp);
      return timestampA - timestampB;
    },
  },
  {
    accessorKey: "time_stamp_diff",
    header: "Timestamp Diff vs Archive",
    sortingFn: (rowA, rowB) => {
      const diffA = parseTimeDiff(rowA.original.time_stamp_diff);
      const diffB = parseTimeDiff(rowB.original.time_stamp_diff);
      return diffA - diffB;
    },
  },
  {
    accessorKey: "blockchain_receipts",
    header: "Blockchain Receipts",
    enableSorting: false,
    size: 120,
    mantineTableBodyCellProps: {
      align: "center",
    },
  },
];

// Function for creating icons

// Function for configuring the table
export const useTableOptions = (columns, data, faIcons, theme) => ({
  columns,
  data,
  icons: faIcons,
  paginationDisplayMode: "pages",
  enableFullScreenToggle: false,
  enableTopToolbar: false,
  enableBottomToolbar: true,
  enableColumnActions: false,
  initialState: { pagination: { pageIndex: 0, pageSize: 20 } },
  withColumnBorders: true,
  mantinePaginationProps: () => ({
    showRowsPerPage: false,
    color: theme.customBackgrounds.table,
    autoContrast: true,
    style: {
      paddingBottom: "1.75em",
    },
  }),
  mantineTableProps: {
    style: {
      border: "none",
      borderCollapse: "collapse",
    },
    className: "html-table html-table-custom",
  },
  mantinePaperProps: {
    withBorder: false,
    shadow: "none",
    className: "html-table-custom-wrap",
  },
  mantineTableBodyCellProps: {
    style: {
      padding: "0.6875em 0.9375em 0.5625em 0.9375em",
    },
  },
  mantineTableHeadCellProps: {
    style: {
      padding: "0.55em 0.9375em 0.4375em 0.9375em",
    },
  },
  mantineTableBodyRowProps: () => ({
    style: {
      borderBottom: theme.customColors.tableBorder,
    },
  }),
  mantineTableHeadRowProps: () => ({
    style: {
      backgroundColor: theme.customBackgrounds.table,
    },
  }),
});
