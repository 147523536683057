import PropTypes from "prop-types";
import React, { useEffect, useState, useCallback } from "react";
import debounce from "lodash/debounce";
import {
  fetchAllCollectionsIsPinned,
  fetchAllCollectionsIsNotPinned,
  URLs,
} from "../../utils/apis";
import { formatStampDetails } from "../../utils/formatStampDetails";
import { handleClickToCopy } from "../../utils/copyToClipboard";
import Pagination from "./Pagination";
import IsPinnedCollectionWindow from "./IsPinnedCollectionWindow";
import LendingPricingSvg from "../../svg/LendingPricingSvg";

const CollectionNames = ({
  staticBasePath,
  onShowConfirmationCollectionCreate,
  nameCollection,
  onNameCollection,
  onNameCollectionError,
  nameCollectionSuccess,
  nameCollectionError,
}) => {
  //Collection validation
  const validationCollection = (value) => {
    const unsafeCharacters = /[<>#%{}|\\^~[\]`]/g;
    const foundUnsafeChars = value.match(unsafeCharacters);

    if (value.length < 3 || value.length > 250) {
      onNameCollectionError(
        "Value error: Name must be between 3 and 250 characters long.",
      );
      return false;
    } else if (foundUnsafeChars) {
      onNameCollectionError(
        `Invalid name. Character not allowed: ${[
          ...new Set(foundUnsafeChars),
        ].join(", ")}`,
      );
      return false;
    } else {
      onNameCollectionError(false);
      return true;
    }
  };

  // Initial state for input values
  const [inputValues, setInputValues] = useState({
    usercollections: [],
    current_page: 1,
    total_pages: 1,
  });

  const [inputValuesIsNotPinned, setInputValuesIsNotPinned] = useState({
    usercollections: [],
    current_page: 1,
    total_pages: 1,
  });

  //Successfully is Pinned name
  const [nameCollectionIsPinned, setNameCollectionIsPinned] = useState("");

  // Show pop up confirmation
  const handleConfirmation = () => {
    if (validationCollection(nameCollection)) {
      onShowConfirmationCollectionCreate(true);
    }
  };
  const handlePageChange = (newPage) => {
    fetchAllCollectionsIsPinned(newPage, setInputValues); // Call the function with the new page number
  };

  const handlePageChangeIsNotPinned = (newPage) => {
    fetchAllCollectionsIsNotPinned(newPage, setInputValuesIsNotPinned); // Call the function with the new page number
  };

  //Is Pinned Collection Name
  const [showIsPinnedWindow, setShowIsPinnedWindow] = useState(false);

  // Collection Name id
  const [collectionID, setCollectionID] = useState("");

  // Collection Is Pinned
  const [isPinned, setIsPinned] = useState(false);

  // Show window is Pinned
  const handleClickIsPinnedWindow = (id, pinned) => {
    setCollectionID(id);
    setIsPinned(pinned);
    setShowIsPinnedWindow(true);
    document.body.style.overflow = "hidden";
  };

  useEffect(() => {
    fetchAllCollectionsIsPinned(1, setInputValues);
    fetchAllCollectionsIsNotPinned(1, setInputValuesIsNotPinned);
  }, [nameCollectionSuccess, nameCollectionIsPinned]); // updating the list after adding a new Collection Name

  // Debounce delay for validation
  const validateNameCollectionDebounce = useCallback(
    debounce((value) => {
      validationCollection(value);
    }, 2000), // Adjust debounce delay as needed
    [],
  );

  // Change input Collection
  const handleChangeCollection = (e) => {
    const value = e.target.value;
    onNameCollection(value);
    validateNameCollectionDebounce(value);
  };

  return (
    <div className="user-profile-tabs-content-item collection-names">
      <h2>Create a Collection</h2>
      <form>
        <div className="user-profile-line rb-display-flex rb-justify-content-center">
          <label
            htmlFor="name"
            className="rb-display-flex rb-align-items-center"
          >
            New collection name:
          </label>
          <div className="collection-names-input-wrap">
            <input
              type="text"
              className={`rb-input collection-names-input collections-input ${
                nameCollectionError ? "error" : ""
              } ${nameCollectionSuccess ? "success" : ""}`}
              id="name"
              value={nameCollection}
              placeholder="Enter new collection name"
              onChange={handleChangeCollection}
            />
            {nameCollectionError && (
              <div className="collection-names-input-error">
                {nameCollectionError}
              </div>
            )}
            {nameCollectionSuccess && (
              <div className="collection-names-input-success">
                {nameCollectionSuccess}
              </div>
            )}
          </div>
          <div className="collection-names-buttons rb-display-flex rb-align-items-center">
            <button
              className="button button-dark"
              type="button"
              onClick={() => handleConfirmation()}
            >
              Create
            </button>
          </div>
        </div>
      </form>
      <h3>Manage Collections</h3>
      <div className="collection-names-table">
        {showIsPinnedWindow && (
          <div className="rb-screen">
            <IsPinnedCollectionWindow
              setShowIsPinnedWindow={setShowIsPinnedWindow}
              collectionID={collectionID}
              isPinned={isPinned}
              setNameCollectionIsPinned={setNameCollectionIsPinned}
            />
          </div>
        )}

        <div className="html-table-wrap">
          <div className="collection-names-table-head">
            <div className="collection-names-table-head-left">
              Pinned Collections
            </div>
            <p>
              Pinned Collections are available to be selected on the Stamp page.
            </p>
          </div>
          {inputValues["usercollections"].length > 0 ? (
            <div className="html-table">
              <table className="pinned-collections">
                <thead>
                  <tr>
                    <th>Collection Name</th>
                    <th>Collection ID</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {inputValues["usercollections"].map(
                    (usercollections_item, index) => (
                      <tr key={index}>
                        <td>{usercollections_item.name}</td>
                        <td>
                          {usercollections_item.cid
                            ? formatStampDetails(usercollections_item.cid)
                            : "-"}
                          <img
                            className="html-table-copy"
                            src={`${staticBasePath}public/images/svg/vb-icon-copy-2.svg`}
                            alt="copy"
                            onClick={() =>
                              handleClickToCopy(usercollections_item.cid)
                            }
                          />
                        </td>
                        <td>
                          <button
                            onClick={() =>
                              handleClickIsPinnedWindow(
                                usercollections_item.id,
                                false,
                              )
                            }
                          >
                            Unpin
                          </button>
                        </td>
                      </tr>
                    ),
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            <p>No Collections</p>
          )}
        </div>
        {/* Pagination Controls */}
        {inputValues["total_pages"] > 1 && (
          <Pagination
            currentPage={inputValues["current_page"]}
            totalPages={inputValues["total_pages"]}
            onPageChange={handlePageChange}
          />
        )}
      </div>
      <div className="collection-names-table">
        <div className="html-table-wrap">
          <div className="collection-names-table-head">
            <div className="collection-names-table-head-left">
              All Unpinned Collections
            </div>
            <p>
              Unpinned Collections are those that you’ve created but are not
              pinned on the Stamp page menu.
            </p>
          </div>
          {inputValuesIsNotPinned["usercollections"].length > 0 ? (
            <div className="html-table">
              <table className="unpinned-collections">
                <thead>
                  <tr>
                    <th>Collection Name</th>
                    <th>Collection ID</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {inputValuesIsNotPinned["usercollections"].map(
                    (usercollections_item, index) => (
                      <tr key={index}>
                        <td>{usercollections_item.name}</td>
                        <td>
                          {usercollections_item.cid
                            ? formatStampDetails(usercollections_item.cid)
                            : "-"}
                          <img
                            className="html-table-copy"
                            src={`${staticBasePath}public/images/svg/vb-icon-copy-2.svg`}
                            alt="copy"
                            onClick={() =>
                              handleClickToCopy(usercollections_item.cid)
                            }
                          />
                        </td>
                        <td>
                          <button
                            onClick={() =>
                              handleClickIsPinnedWindow(
                                usercollections_item.id,
                                true,
                              )
                            }
                          >
                            Pin
                          </button>
                        </td>
                      </tr>
                    ),
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            <p>No Collections</p>
          )}
        </div>
        {/* Pagination Controls */}
        {inputValuesIsNotPinned["total_pages"] > 1 && (
          <Pagination
            currentPage={inputValuesIsNotPinned["current_page"]}
            totalPages={inputValuesIsNotPinned["total_pages"]}
            onPageChange={handlePageChangeIsNotPinned}
          />
        )}
      </div>
      {showIsPinnedWindow && <div className="rb-loading-bg"></div>}

      <div className="reports-buttons collection-names-back-buttons rb-display-flex rb-align-items-center">
        <a
          href={URLs.STAMP_URL}
          className="button rb-btn button-dark rb-display-flex rb-justify-content-center rb-align-items-center"
        >
          <LendingPricingSvg />
          Back to Stamper
        </a>
      </div>
    </div>
  );
};
CollectionNames.propTypes = {
  staticBasePath: PropTypes.string.isRequired,
  onUpdateStampQuota: PropTypes.func,
  onShowConfirmationCollectionCreate: PropTypes.func.isRequired,
  showConfirmationCollectionCreate: PropTypes.bool,
  nameCollection: PropTypes.string.isRequired,
  nameCollectionSuccess: PropTypes.string.isRequired,
  nameCollectionError: PropTypes.bool.isRequired,
  onNameCollectionError: PropTypes.func.isRequired,
  onNameCollection: PropTypes.func.isRequired,
};
export default CollectionNames;
