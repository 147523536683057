import { notifications } from "@mantine/notifications";

export const parseMetadata = (lines) => {
  if (lines.length < 2) {
    notifications.show({
      title: "Error",
      message: "Invalid metadata file: file must have at least two lines",
      color: "red",
    });
  }

  const headers = lines[0]?.split(",").map((header) => header.trim());
  const data = lines[1]?.split(",").map((value) => value.trim());

  if (!headers || headers.length < 2) {
    notifications.show({
      title: "Error",
      message: "Invalid metadata file format: missing or malformed headers",
      color: "red",
    });
  }

  if (!data || data.length < 2) {
    notifications.show({
      title: "Error",
      message: "Invalid metadata file format: missing or malformed data",
      color: "red",
    });
  }

  const headerMap = headers.reduce((map, header, index) => {
    map[header] = index;
    return map;
  }, {});
  const metaHeaders = ["collection_name", "user_address"];
  for (const header of metaHeaders) {
    if (!(header in headerMap)) {
      notifications.show({
        title: "Error",
        message: `Missing required header '${header}' in metadata file`,
        color: "red",
      });
    }
  }

  const [collectionName, userAddress] = metaHeaders.map(
    (header) => data[headerMap[header]],
  );
  if (!collectionName || !userAddress) {
    notifications.show({
      title: "Error",
      message: "Invalid metadata file: missing required data",
      color: "red",
    });
  }

  return { collectionName, userAddress };
};
