//fetch Form
import { sha3_256 } from "js-sha3";
import { MIME_TYPES } from "@mantine/dropzone";
import { notifications } from "@mantine/notifications";
import { processZip } from "./zipFileTransformer";
import { parseMetadata } from "./parseMetadata";

// Fetch form data and submit the collection file to the server
export const foundCollectionFetch = async (collectionData, csrfToken) => {
  const formData = new FormData();
  formData.append(
    "file",
    new Blob([collectionData], { type: "text/csv" }),
    "file.csv",
  );

  try {
    const response = await fetch("/verify/verify-user-collection/", {
      method: "POST",
      headers: {
        "X-CSRFToken": csrfToken, // Include the CSRF token
      },
      body: formData,
    });

    if (response.ok) {
      const data = await response.json();
      return { success: true, data };
    } else {
      const errorData = await response.json();
      return { success: false, error: errorData.error };
    }
  } catch (error) {
    console.error("Error:", error);
    throw new Error("Error uploading CSV");
  }
};

// Generate a SHA3-256 hash from a string
const sha3_256_hash_string = (data) => {
  const encoder = new TextEncoder();
  const byteArray = encoder.encode(data); // Convert string to bytes (Uint8Array)
  const hash = sha3_256.update(byteArray).hex(); // Compute the hash
  return "0x" + hash; // Add '0x' prefix
};

// Convert CSV data to JSON format
const csvToJson = (csvData) => {
  const lines = csvData.split("\n");
  const headers = lines[0].split(",");
  const json = lines.slice(1).reduce((acc, line) => {
    if (line.trim() === "") return acc; // if empty lines skip
    const values = line.split(",");
    const obj = headers.reduce((headerAcc, header, idx) => {
      headerAcc[header.trim()] = values[idx]?.trim(); // Map object keys to values
      return headerAcc;
    }, {});
    acc.push(obj);
    return acc;
  }, []);
  return json;
};

// Handle dropped files (CSV or ZIP) and process them
export const handleDropProcess = (
  droppedFiles,
  setFiles,
  form,
  setNewData,
  onErrorMessage,
) => {
  setFiles(droppedFiles);

  droppedFiles.forEach((file) => {
    // Read the file as text
    const reader = new FileReader();
    reader.onload = async (e) => {
      const content = e.target.result; // Get the content of the CSV file

      if (file.type === MIME_TYPES.csv) {
        // Process CSV with the hash
        await processCSVWithHash(
          content,
          setFiles,
          form,
          setNewData,
          onErrorMessage,
        );
      } else if (file.type === MIME_TYPES.zip) {
        await processZIPWithHash(
          content,
          setFiles,
          form,
          setNewData,
          onErrorMessage,
        );
      }
    };
    if (file.type === MIME_TYPES.zip) {
      reader.readAsArrayBuffer(file); // Read ZIP as binary
    } else {
      reader.readAsText(file); // Read CSV as text
    }
  });
};

// Convert JSON data to CSV format
const jsonToCsv = (jsonData, headers) => {
  let csv = headers.join(",") + "\n";
  jsonData.forEach((row) => {
    csv += headers.map((header) => row[header]).join(",") + "\n";
  });
  return csv;
};

// Group data by a specific column
const groupBy = (data, column) => {
  const uniqueKeys = Array.from(new Set(data.map((row) => row[column])));
  return uniqueKeys.map((key) => ({
    [column]: key,
    group: data.filter((row) => row[column] === key),
  }));
};

// Remove a specific column from data
/* eslint-disable no-unused-vars */
const removeColumn = (data, column) => {
  return data.map((row) => {
    const { [column]: _, ...rest } = row; // Exclude the specified column
    return rest;
  });
};

// Process CSV data with metadata and hashing
/* eslint-enable no-unused-vars */
const processCSVWithHash = async (
  csvData,
  setFiles,
  form,
  setNewData,
  onErrorMessage,
) => {
  const lines = csvData.split("\n");
  // Check if metadata exists
  if (lines.length < 2) {
    notifications.show({
      title: "Error",
      message: "Metadata is missing in the CSV file.",
      color: "red",
    });
    onErrorMessage("Metadata is missing in the CSV file.");
    setFiles([]);
    form.reset();
    return;
  }

  const { collectionName, userAddress } = parseMetadata(lines);
  // Validation for collectionName
  const unsafeCharacters = /[<>#%{}|\\^~[\]`]/g;
  if (!collectionName) {
    notifications.show({
      title: "Error",
      message: "Collection name is missing.",
      color: "red",
    });
    onErrorMessage("Collection name is missing.");
    setFiles([]);
    form.reset();
    return;
  }

  if (collectionName.length < 3 || collectionName.length > 250) {
    notifications.show({
      title: "Error",
      message: "Collection name must be between 3 and 250 characters long.",
      color: "red",
    });
    onErrorMessage(
      "Collection name must be between 3 and 250 characters long.",
    );
    setFiles([]);
    form.reset();
    return;
  }

  if (collectionName.match(unsafeCharacters)) {
    notifications.show({
      title: "Error",
      message: "Collection name contains unsafe characters.",
      color: "red",
    });
    onErrorMessage("Collection name contains unsafe characters.");
    setFiles([]);
    form.reset();
    return;
  }

  // Validation for userAddress
  const addressRegex = /^0x[a-fA-F0-9]{40}$/;
  if (!userAddress) {
    notifications.show({
      title: "Error",
      message: "User address is missing.",
      color: "red",
    });
    onErrorMessage("User address is missing.");
    setFiles([]);
    form.reset();
    return;
  }

  if (!addressRegex.test(userAddress)) {
    notifications.show({
      title: "Error",
      message:
        "User address must be a valid Ethereum address (0x followed by 40 hex characters).",
      color: "red",
    });

    onErrorMessage(
      "User address must be a valid Ethereum address (0x followed by 40 hex characters).",
    );
    setFiles([]);
    form.reset();
    return;
  }

  const dataLines = lines.slice(2).join("\n");
  const jsonData = csvToJson(dataLines);
  const groupedData = groupBy(jsonData, "t");

  const rows = groupedData.map((group) => {
    const cleanedData = removeColumn(group.group, "t");
    const headers = Object.keys(cleanedData[0]);
    const csvContent = jsonToCsv(cleanedData, headers);
    const hash = sha3_256_hash_string(csvContent);
    return `${group.t},${hash}`;
  });
  const finalCsv = `collection_name,user_address\n${collectionName},${userAddress}\nt,c\n${rows.join(
    "\n",
  )}`;
  setNewData(finalCsv);
  form.setValues({ new_csv: finalCsv });
  return finalCsv;
};

// Process ZIP data and generate new CSV with hashing
const processZIPWithHash = async (zipData, setFiles, form, setNewData) => {
  const finalCsv = await processZip(zipData);
  setNewData(finalCsv);
  form.setValues({ new_csv: finalCsv });
  return finalCsv;
};
