import React from "react";
import moment from "moment";
import {
  IconArrowsSort,
  IconSortAscending,
  IconSortDescending,
} from "@tabler/icons-react";

export const timeMapping = {
  0: "0 seconds",
  1: "1 second",
  10: "10 seconds",
  60: "1 minute",
  600: "10 minutes",
  3600: "1 hour",
  86400: "1 day",
};

export const parseTimeDiff = (timeDiff) => {
  const match = timeDiff.match(/([+-]?\d+)\s*(second|minute|hour|day)s?/);
  if (!match) return 0;

  const [, value, unit] = match;
  const parsedValue = parseInt(value, 10);

  switch (unit) {
    case "second":
      return parsedValue * 1000;
    case "minute":
      return parsedValue * 60 * 1000;
    case "hour":
      return parsedValue * 60 * 60 * 1000;
    case "day":
      return parsedValue * 24 * 60 * 60 * 1000;
    default:
      return 0;
  }
};

export const formatTimeDifferenceFromSeconds = (differenceInSeconds) => {
  const duration = moment.duration(differenceInSeconds, "seconds");
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  if (days > 1) {
    // >1 day: Show day + hours to 2 decimals
    const totalHours = (hours + minutes / 60 + seconds / 3600).toFixed(2);
    return `${days} day${days > 1 ? "s" : ""} and ${totalHours} hour${
      totalHours !== "1.00" ? "s" : ""
    }`;
  }

  if (days === 1 || hours >= 1) {
    // >1 hour and <1 day: Show hours + minutes to 2 decimals
    const totalMinutes = (minutes + seconds / 60).toFixed(2);
    return `${hours} hour${hours > 1 ? "s" : ""} and ${totalMinutes} minute${
      totalMinutes !== "1.00" ? "s" : ""
    }`;
  }

  // <1 hour: Show minutes and seconds without decimals
  const parts = [];
  if (minutes > 0) parts.push(`${minutes} minute${minutes > 1 ? "s" : ""}`);
  parts.push(`${seconds} second${seconds !== 1 ? "s" : ""}`);
  return parts.join(" and ");
};

// Function to calculate the time difference between two timestamps
export const calculateTimeDifference = (timestamp1, timestamp2) => {
  // Convert timestamps to seconds since UNIX epoch
  const timeInSeconds1 = moment(timestamp1).unix();
  const timeInSeconds2 = moment(timestamp2).unix();

  // Calculate the absolute difference in seconds
  const differenceInSeconds = Math.abs(timeInSeconds1 - timeInSeconds2);

  // Format the difference using the helper function
  return formatTimeDifferenceFromSeconds(differenceInSeconds);
};

export const calculateTimeDifferenceInSeconds = (timestamp1, timestamp2) => {
  // Convert timestamps to seconds since UNIX epoch
  const timeInSeconds1 = moment(timestamp1).unix();
  const timeInSeconds2 = moment(timestamp2).unix();

  // Calculate the absolute difference in seconds
  return Math.abs(timeInSeconds1 - timeInSeconds2);
};

export const useTableIcons = () => ({
  IconArrowsSort: (props) => (
    <IconArrowsSort {...props} size={18} stroke={1.5} />
  ),
  IconSortAscending: (props) => (
    <IconSortAscending {...props} size={18} stroke={1.5} />
  ),
  IconSortDescending: (props) => (
    <IconSortDescending {...props} size={18} stroke={1.5} />
  ),
});

// Utility function to parse a custom date string format into a Date object
export const parseDate = (dateString) => {
  const [date, time] = dateString.split(" | ");
  return new Date(`${date} ${time}`);
};

export const downloadJsonToCsvMissing = (
  dataArray,
  fileName = "debugging_data.csv",
) => {
  // Check if the data array is valid and contains data
  if (!dataArray || dataArray.length === 0) {
    console.error("No data available to generate CSV");
    return;
  }

  // Determine the keys for 'cid' and 'timestamp'
  const keys = dataArray[0];
  const cidKey = keys.csv_cid ? "csv_cid" : "objectCid";

  // Initialize CSV headers
  let csvContent = "cid,timestamp\n";

  // Sort the data array by timestamp
  const sortedData = dataArray.sort((a, b) => {
    const timestampA = new Date(
      a["csv_timestamp"] || a["timestamp"] || 0,
    ).getTime();
    const timestampB = new Date(
      b["csv_timestamp"] || b["timestamp"] || 0,
    ).getTime();
    return timestampA - timestampB; // Ascending order
  });
  sortedData.forEach((item) => {
    const cid = item[cidKey] || "";
    const timestamp = item["csv_timestamp"] || item["timestamp"] || "";
    csvContent += `${cid},${timestamp}\n`;
  });

  // Create a Blob object for the CSV content
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);

  // Create a temporary anchor element to trigger the download
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName); // Set the filename for the download
  document.body.appendChild(link);
  link.click(); // Programmatically click the link to start the download

  // Remove the temporary link from the DOM after download
  document.body.removeChild(link);
};
