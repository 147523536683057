import React from "react";
import { Flex, Image, Text, Box } from "@mantine/core";
import { timeMapping } from "./resultScreen";

export const useDataTolerance = (
  timeCollections,
  matchedRecords,
  staticBasePath,
) => {
  if (timeCollections && typeof timeCollections === "object") {
    let hasReachedFullMatch = false; // Track when we reach the first full match
    let accumulatedValue = 0;
    return Object.entries(timeCollections).map(([key, value]) => {
      const matchedLength = matchedRecords.length;
      accumulatedValue += value;
      let validation_check;
      if (
        (accumulatedValue === matchedLength && matchedLength > 0) ||
        hasReachedFullMatch
      ) {
        // Once we reach the first full match, display 100% within tolerance
        hasReachedFullMatch = true;
        validation_check = (
          <Flex align="center" gap={5}>
            <Image
              w={19}
              src={`${staticBasePath}public/images/svg/vb-icon-verify.svg`}
              alt=""
            />
            <Text size="1em">100% within tolerance</Text>
          </Flex>
        );
      } else {
        // Until we reach the full match, display the value/length tolerance message
        validation_check = (
          <Flex align="center" justify="space-between" gap={5}>
            <Box>
              <Flex align="center" justify="space-between" gap={5}>
                <Image
                  w={19}
                  src={`${staticBasePath}public/images/svg/rb-info-yellow.svg`}
                  alt=""
                />
                <Text size="1em">
                  {matchedLength === 0 ? (
                    <>no files available to compare</>
                  ) : (
                    <>
                      {accumulatedValue}/{matchedLength} within tolerance
                    </>
                  )}
                </Text>
              </Flex>
            </Box>
          </Flex>
        );
      }

      return {
        tolerance: timeMapping[key] || `${key} seconds`, // Map key to human-readable format
        validation_check, // Conditional rendering of validation check
      };
    });
  }
};

export const useColumnsTolerance = () => [
  { accessorKey: "tolerance", header: "Tolerance", size: 120 },
  {
    accessorKey: "validation_check",
    header: (
      <Flex gap={5} align="center">
        <Text>Validation Check</Text>
      </Flex>
    ),
  },
];

export const useTableTolerance = (columnsTolerance, dataTolerance, theme) => {
  return {
    columns: columnsTolerance,
    data: dataTolerance,
    enableSorting: false,
    enableFullScreenToggle: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,

    enableColumnActions: false,

    withColumnBorders: true,

    mantineTableProps: {
      style: {
        border: "none",
        borderCollapse: "collapse",
      },
      className: "html-table html-table-custom",
    },
    mantinePaperProps: {
      withBorder: false,
      shadow: "none",
      className: "html-table-custom-wrap html-table-custom-wrap-tolerance",
    },
    mantineTableBodyRowProps: () => ({
      style: {
        borderBottom: theme.customColors.tableBorder,
      },
    }),
    mantineTableBodyCellProps: {
      style: {
        padding: "0.3em 1.28125em 0.25em 1.28125em",
      },
    },
    mantineTableHeadRowProps: {
      style: {
        backgroundColor: theme.customBackgrounds.table,
      },
    },
    mantineTableHeadCellProps: {
      style: {
        padding: "0.2em 1.28125em 0.1em 1.28125em",
      },
    },
  };
};
