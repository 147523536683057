import JSZip from "jszip";
import { sha3_256 } from "js-sha3";
import { notifications } from "@mantine/notifications";
import { parseMetadata } from "./parseMetadata";
import dayjs from "dayjs";
// Read and parse a ZIP file asynchronously
const readZipFile = async (zipFile) => {
  const zip = new JSZip();
  return zip.loadAsync(zipFile);
};

// Extract and format a timestamp from a CSV file name
const extractAndFormatTimestamp = (fileName) => {
  if (!fileName) {
    notifications.show({
      title: "Error",
      message: "File name is missing.",
      color: "red",
    });
    return null;
  }

  // Define supported patterns and formats
  const patterns = [
    { regex: /.*(\d{14})\.csv$/, format: "YYYYMMDDHHmmss" }, // Full timestamp
    { regex: /.*(\d{12})\.csv$/, format: "YYYYMMDDHHmm" }, // Without seconds
    { regex: /.*(\d{10})\.csv$/, format: "YYYYMMDDHH" }, // Without minutes and seconds
    { regex: /.*(\d{8})\.csv$/, format: "YYYYMMDD" }, // Without hours, minutes and seconds
  ];

  let matchedPattern = null;

  // Find the first matching pattern
  for (const pattern of patterns) {
    if (pattern.regex.test(fileName)) {
      matchedPattern = pattern;
      break;
    }
  }

  if (!matchedPattern) {
    notifications.show({
      title: "Error",
      message: "Invalid file name format.",
      color: "red",
    });
    return null;
  }

  // Extract the timestamp using a capturing group
  const timestampMatch = fileName.match(matchedPattern.regex);
  const timestampString = timestampMatch ? timestampMatch[1] : null;

  if (!timestampString) {
    notifications.show({
      title: "Error",
      message: "Unable to extract timestamp from file name.",
      color: "red",
    });
    return null;
  }

  // Parse the timestamp using the matched format
  let date;
  if (matchedPattern.format === "YYYYMMDD") {
    // For date-only formats, parse as UTC explicitly at midnight
    date = dayjs.utc(timestampString, matchedPattern.format, true);
  } else {
    // For other formats, parse normally
    date = dayjs(timestampString, matchedPattern.format, true);
  }

  if (!date.isValid()) {
    notifications.show({
      title: "Error",
      message: "Invalid timestamp in file name.",
      color: "red",
    });
    return null;
  }

  // Format the date to the desired output
  return date.utc().format("YYYY-MM-DD HH:mm:ss[+00:00]");
};

// Generate a SHA3-256 hash from a string
const sha3_256_hash_string = (data) => {
  const encoder = new TextEncoder();
  const byteArray = encoder.encode(data); // Convert string to bytes (Uint8Array)
  const hash = sha3_256.update(byteArray).hex(); // Compute the hash
  return "0x" + hash; // Add '0x' prefix
};
// Generate a hash and timestamp for a file's content
const stampFile = (filePath, fileContent) => {
  const fileName = filePath.split("/").pop();
  const timestamp = extractAndFormatTimestamp(fileName);
  const hash = sha3_256_hash_string(fileContent);
  return `${timestamp},${hash}`;
};

// Generate a CSV file from the contents of a ZIP file
const generateCsvFromZip = async (zipContent) => {
  let csvMetadata = ""; // Metadata CSV content
  let csvOutput = "t,c\n"; // Data CSV header

  await Promise.all(
    Object.entries(zipContent.files).map(async ([filePath, file]) => {
      if (filePath.includes("__MACOSX") || !filePath.endsWith(".csv")) return;

      const fileContent = await file.async("string");
      if (filePath.endsWith("vbase_metadata.csv")) {
        const lines = fileContent.split("\n");
        const { collectionName, userAddress } = parseMetadata(lines);

        if (collectionName && userAddress) {
          csvMetadata = `collection_name,user_address\n${collectionName},${userAddress}\n`;
        }
      } else {
        const processedLine = stampFile(filePath, fileContent);
        if (processedLine) {
          csvOutput += processedLine + "\n";
        }
      }
    }),
  );
  return csvMetadata + csvOutput;
};

// Process a ZIP file and return a formatted CSV string
export const processZip = async (zipFile) => {
  const zipContent = await readZipFile(zipFile);
  return generateCsvFromZip(zipContent);
};
